.fade {
	animation: fade-in-keyframes 5s;
}
@keyframes fade-in-keyframes {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

body {
	background-color: #202020;
}

a {
	color: white;
	text-decoration: none;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-float infinite 3s ease-in-out;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.App-link {
	color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}


input[type='number'] {
	-moz-appearance: textfield;
} */

/*  GENERAL */
.brew-list-even-row-dark {
	background-color: rgba(30, 30, 30, 0.9);
}

.brew-list-odd-row-dark {
	background-color: rgba(30, 30, 30, 0.5);
}

.brew-list-even-row-light {
	background-color: rgba(228, 228, 228, 0.739);
}

.brew-list-odd-row-light {
	background-color: rgba(255, 255, 255, 0.75);
}

.brew-list-light {
	color: rgba(15, 15, 15, 1) !important;
}

.brew-list-dark {
	color: white !important;
}

.background-dark {
	background-size: 5px 5px;
	background: repeating-linear-gradient(
		45deg,
		#606060 0,
		#606060 1px,
		#202020 0px,
		#202020 15px
	);
}

.background-light {
	background-size: 5px 5px;
	background-image: repeating-linear-gradient(
		45deg,
		#999999 0,
		#999999 0.5px,
		#d3d3d3 0,
		#d3d3d3 50%
	);
	background-position: 0.1rem 0;
}

/*  CSS GRID  */

.brew-list-grid-4 {
	display: grid;
	overflow-x: auto;
	grid-template-columns: 1fr 1fr 3fr 1fr;
}

.brew-list-grid-tap-5 {
	display: grid;
	overflow-x: auto;
	grid-template-columns: 0.25fr 1fr 1fr 3fr 1fr;
}

.brew-list-grid-bottle-5 {
	display: grid;
	overflow-x: auto;
	grid-template-columns: 1fr 1fr 3fr 1fr 0.5fr;
}

.brew-list-grid-6 {
	display: grid;
	overflow-x: auto;
	grid-template-columns: 0.25fr 1fr 1fr 3fr 1fr 1fr;
}

.brew-list-grid-4 div {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.brew-list-grid-tap-5 > div {
	text-align: center;
	padding: 0.5rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.brew-list-grid-bottle-5 > div {
	text-align: center;
	padding: 0.5rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.brew-list-grid-6 > div {
	text-align: center;
	padding: 0.5rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	/* 	min-height: 100%; */
}

.brew-list-grid-4 p {
	margin: 1em;
}

.brew-list-grid-tap-5 p {
	margin: 1em;
}

.brew-list-grid-bottle-5 p {
	margin: 1em;
	text-align: center;
}

.brew-list-grid-6 p {
	margin: 1em;
}

/*  TAP CIRCLE */
.tap-circle {
	border-radius: 3rem !important;
	width: 3rem !important;
	line-height: 3rem !important;
}

.tap-circle p {
	margin: 0;
	padding: 0;
	font-weight: bold;
}

.tap-circle.dark {
	background-color: rgba(15, 15, 15, 0.75);
	color: white;
}

.tap-circle.light {
	background-color: rgba(185, 185, 185, 0.75);
	color: rgba(15, 15, 15, 1) !important;
}

/*  PINT CONTAINER */
.pint-container {
	position: relative;
	height: 100px;
	width: 75px;
	margin: 0 auto;
	margin-bottom: 0.5rem;
}

.pint-indicator {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	-webkit-mask-image: url('./img/srm/pint-mask.png');
	-o-mask-image: url('./img/srm/pint-mask.png');
	-moz-mask-image: url('./img/srm/pint-mask.png');
	mask-image: url('./img/srm/pint-mask.png');
}

.pint-stroke-dark {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	background: url('./img/srm/pint-stroke-white.png');
}

.pint-stroke-light {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	background: url('./img/srm/pint-stroke-black.png');
}

/*  BOTTLE CONTAINER */
.bottle-container {
	position: relative;
	height: 100px;
	width: 75px;
	margin: 0 auto;
	margin-bottom: 0.5rem;
}

.bottle-indicator {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	-webkit-mask-image: url('./img/srm/bottle-mask.png');
	-o-mask-image: url('./img/srm/bottle-mask.png');
	-moz-mask-image: url('./img/srm/bottle-mask.png');
	mask-image: url('./img/srm/bottle-mask.png');
}

.bottle-stroke-dark {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	background: url('./img/srm/bottle-stroke-white.png');
}

.bottle-stroke-light {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 2;
	background: url('./img/srm/bottle-stroke-black.png');
}

/*  IBU CONTAINER */
.ibu-container {
	position: relative;
	height: 100px;
	width: 75px;
	margin: 0 auto;
	margin-bottom: 0.5rem;
}

.ibu-indicator-dark {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: url('./img/ibu/ibu-stroke-white.png') no-repeat bottom left;
}

.ibu-indicator-light {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: url('./img/ibu/ibu-stroke-black.png') no-repeat bottom left;
}

.ibu-full-dark {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: url('./img/ibu/ibu-full-white.png') no-repeat bottom left;
}

.ibu-full-light {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background: url('./img/ibu/ibu-full-black.png') no-repeat bottom left;
}

/*  BOTTLE # CONTAINER */
.bottle-num-dark {
	vertical-align: middle;
	align-content: center;
	width: 100px;
	height: 100px;
	background-image: url('./img/bottle/bottle-cap-white.png');
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
}

.bottle-num-light {
	vertical-align: middle;
	align-content: center;
	width: 100px;
	height: 100px;
	background-image: url('./img/bottle/bottle-cap-black.png');
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
}

.bottle-circle {
	border-radius: 5em;
	-moz-border-radius: 5em;
	-webkit-border-radius: 5em;
	display: inline-block;
	font-weight: bold;
	line-height: 80px;
	margin-right: 0px;
	text-align: center;
	width: 80px;
	z-index: 5 !important;
	margin-top: 0.6em;
}

.bottle-circle.dark {
	background-color: rgba(10, 10, 10, 0.75);
}

.bottle-circle.light {
	background-color: rgba(195, 195, 195, 0.75);
}

/*  KEG CONTAINER */
.keg-container {
	position: relative;
	height: 100px;
	width: 75px;
	margin: 8px auto;
}

.keg-indicator-dark {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100px;
	background: url('./img/keg/stroke-dark.png') no-repeat bottom left;
}

.keg-indicator-light {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100px;
	background: url('./img/keg/stroke-light.png') no-repeat bottom left;
}

.keg-indicator-dark .keg-full-dark {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100px;
	background: url('./img/keg/green-dark.png') no-repeat bottom left;
}

.keg-indicator-light .keg-full-light {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100px;
	background: url('./img/keg/green-light.png') no-repeat bottom left;
}

.keg-indicator-dark .keg-empty-dark {
	background-image: url('./img/keg/empty-dark.png');
}

.keg-indicator-light .keg-empty-light {
	background-image: url('./img/keg/empty-light.png');
}

.keg-indicator-dark .keg-red-dark {
	background-image: url('./img/keg/red-dark.png');
}

.keg-indicator-light .keg-red-light {
	background-image: url('./img/keg/red-light.png');
}

.keg-indicator-dark .keg-orange-dark {
	background-image: url('./img/keg/orange-dark.png');
}

.keg-indicator-light .keg-orange-light {
	background-image: url('./img/keg/orange-light.png');
}

.keg-indicator-dark .keg-yellow-dark {
	background-image: url('./img/keg/yellow-dark.png');
}

.keg-indicator-light .keg-yellow-light {
	background-image: url('./img/keg/yellow-light.png');
}

.keg-indicator-dark .keg-green-dark {
	background-image: url('./img/keg/green-dark.png');
}

.keg-indicator-light .keg-green-light {
	background-image: url('./img/keg/green-light.png');
}

/*  POUR MODAL */
.brew-list-modal {
	display: none;
	position: fixed;
	z-index: 100;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.75);
}

.brew-list-modal-content.pour-modal {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-size: 5px 5px;
	background-image: repeating-linear-gradient(
		45deg,
		#404040 0,
		#404040 0.5px,
		#202020 0,
		#202020 50%
	);
	background-position: 0.1rem 0;
	margin: auto;
	padding: 20px;
	border: 1px solid #888;
}

.brew-list-modal-div {
	text-align: center;
}

.brew-list-modalBtn {
	display: inline-block;
	margin: 5px;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	background-image: none;
	padding: 6px 12px;
	font-size: 1.4rem;
	border-radius: 0px;
	border: 1px solid transparent;
	text-decoration: none;
	user-select: none;
}

#pourAmt {
	max-width: 200px;
	font-size: 2rem;
}

#pourBtn {
	background-color: #fff;
}
